.trustpilot-wrapper {
  width: 100%;
  font-family: Helvetica;
  text-align: center;
  color: $black;
  margin: 1em 0;

  &.bottom {
    position: absolute;
    bottom: 10vh;
  }

  a {
    display: inline-block;
    text-decoration: none;
    color: $dark-blue;;
  }
}

.trustpilot-box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;

  div {
    margin: 0.25em;
    font-size: 14px;
  }
  
  .trustpilot-logo {
    width: 150px;

    &.small {
      width: 100px
    }
  }

  .trustpilot-stars {
    width: 100%;
    max-width: 200px;
    margin-top: 0.25em;
    height: 30px;

    &.small {
      max-width: 125px;
    }
  }

  &.center {
    justify-content: center;
  }
}

@include media-from-medium {
  .trustpilot-box {
    justify-content: left;
  }
}