.confirmed-page {
  background: $main-brand-color;
  color: $white;

  .wrapper {
    padding: 5em 0;
    text-align: center;
  }

  p {
    font-size: 1.25em;
    margin: 1em 0 0;
  }

  i {
    font-size: 10rem;
    margin: 0 0 0.1em;
    display: inline-block;
  }
}
