@import 'mixins/_include';
@import 'global/_include';
@import 'angular';

body {
  color: $main-font-color;
  background: $grey;
  line-height: 1.3;
}

body, input, select, textarea, button {
  font-family: $default-font;
}

.wrapper,
.wrapper-compact-wide,
.wrapper-compact {
  width: 100%;
  margin: 0 auto;
  padding: 0 0.9rem;
  box-sizing: border-box;
}

.wrapper {
  max-width: 76rem;
}

.wrapper-compact-wide {
  max-width: 52rem;
}

.wrapper-compact {
  max-width: 42rem;
}

.wrapper-header-information {
  max-width: 42rem;
  text-align: center;
  margin: 4em auto 3em;

  @include media-small {
    margin: 3em auto 2em;
  }

  h1 {
    margin-bottom: 1em;
  }
}

.wrapper-quiz {
  // This one will only be used for the quiz
  // Change this if the quiz needs to be wider on larger screens
  max-width: 40rem;
}

.fullwidth {
  display: block;
  width: 100%;
  text-align: center;
}

.arrow-link {
  color: $main-brand-color;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: transparent;
  font-size: large;
  padding: 0;
  display: flex;
  align-items: flex-end;

  i {
    transform: scaleX(-1);
    display: inline-block;
    font-size: 0.8em;
    vertical-align: middle;
    margin-left: 0.3em;
  }
}

.add-vitamin-link {
  color: $main-brand-color;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  border: none;
  background-color: transparent;
  font-size: large;
  padding: 0;
}

@import 'pages/_include';
@import 'elements/_include';
@import 'elements/popups/_include';
