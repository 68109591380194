.login-wrapper {
  padding: 3.5em 0 10em;
  box-sizing: border-box;
}

.email-wrapper {
  max-width: 18em;
  margin: 0 auto;

  .submit-button {
    display: block;
    width: 100%;
  }
}

.login-head-image {
  width: 100px;
}

.login-form-wrapper {
  max-width: 40em;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  h1 {
    margin: 0.5em;
    font-size: 1.75em;
  }

  p {
    margin: 1em 3em 1em;
    position: relative;
  }
}

@media (max-height: 40em) {
  .login-form-wrapper {
    position: static;
    transform: none;
  }
}
