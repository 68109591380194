.referral-page {
  background-color: $black;
  font-size: 20px;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  transform: translateX(-100%);
  transition: transform ease-in-out 0.25s;
  overflow-y: scroll;

  .referral-wrapper {
    background-color: $white;
    max-width: 20em;
    margin: 5em auto;
    padding-bottom: 1em;
    border-radius: 20px;

    @include media-medium {
      margin: 1em;
    }
  }

  .referral-head-wrapper {
    background-image: url("/assets/image/content/kiwi-zinc.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 10em;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .referral-text-wrapper {
    p, b {
      display: block;
      text-align: center;
    }
    
    p {
      padding: 1em;
    }
  }
}

.close-circle {
  height: 2.5em;
  width: 2.5em;
  background-color: $white;
  border-radius: 50%;
  margin: 1em;
  float: right;

  .cross {
    margin-top: 0.85em;
    display: block;
    text-align: center;
    font-size: smaller;
  }
}

.navigation-active {
  transform: translateX(0);
}

#referral-button {
  display: block;
  margin: 1em auto;
  text-align: center;
  text-decoration: none;
  background-color: $turquoise;
  max-width: 80%;
  padding: 0.5em;
  color: $white;
  border-radius: 10px;
}
