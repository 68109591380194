.progress-bar {
  width: 100%;
  height: 0.6rem;
  position: fixed;
  bottom: 0;

  .current-status {
    width: 0%;
    height: 100%;
    display: block;
    background: $main-brand-highlight-color;
    transition: width 0.5s ease-in;
  }
}

.blend-progress {
  font-size: 0.8em;
  font-weight: bold;
  margin: 2em 0 2em;
  text-align: center;

  ol {
    display: block;
    text-align: center;
  }

  li {
    display: inline-block;

    span {
      color: $dark-grey;
      padding: 1em 1em 0;
      position: relative;
      margin: 0 0.2em;
      display: block;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 0.5em;
        border-radius: calc($main-border-radius / 2);
        background: $dark-grey;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .active span {
    color: $main-brand-color;

    &::before {
      background: $main-brand-color;
    }
  }
}

.blend-information + vi-blend-progress .blend-progress {
  margin-top: 2em;
}
