.ui-carousel .carousel-btn,
.v-middle {
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}


[class^="ui-icon-"]:before,
[class*=" ui-icon-"]:before {
  font-family: Viteezy-Icons;
  font-style: initial;
}

.ui-icon-prev:before {
  content: "\e93e";
}

.ui-icon-next:before {
  content: "\e93f";
}

.ui-carousel {
  display: block;
  margin-bottom: 30px;
}

.ui-carousel .carousel-wrapper {
  position: relative;
  margin: 1em 0em 5em;
}

.ui-carousel .track-wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.ui-carousel .track {
  position: relative;
  display: block;
  float: left;
}

.ui-carousel .slide {
  float: left;
  height: 100%;
  min-height: 1px;
}

.ui-carousel {
  .carousel-btn {
    position: absolute;
    z-index: 1;
    background-color: white;
    outline: none;
    border: 1px solid $main-border-color;
    padding: 0.4em 0.45em 0.25em;
    font-weight: bold;
    font-size: 1.5em;
    border-radius: 50%;
    cursor: pointer;
    display: none;
  }

  .carousel-prev .carousel-btn {
    left: -0.5em;
  }

  .carousel-next .carousel-btn {
    right: -0.5em;
  }
}

.ui-carousel .carousel-btn:hover {
  opacity: 1;
}

.ui-carousel .carousel-disable {
  opacity: 0.5;
}

.ui-carousel .carousel-disable .carousel-btn:hover {
  opacity: 0.75;
}

.carousel-dots {
  position: absolute;
  bottom: -2em;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.carousel-dots li {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.carousel-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 15px;
  height: 15px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.carousel-dots li button:before {
  font-size: 2rem;
  line-height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: $main-brand-color;
  -webkit-font-smoothing: antialiased;
}

.carousel-dots li.carousel-active button:before {
  opacity: 0.75;
}

.mobile-carousel {
  display: none;
}

.ng-hide {
  display: none;
}

.ingredient-carousel {
  .carousel-btn {
    background-color: $main-brand-color;
    color: white;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
    border: none;
  }

  .ui-icon-next:before {
    transform: scaleX(-1);
    display: inline-block;
    content: "\e90e";
  }

  .ui-icon-prev:before {
    content: "\e90e";
  }

  .carousel-wrapper {
    margin: 1em 0;
  }
}

.carousel-next.carousel-disable,
.carousel-prev.carousel-disable {
  display: none;
}

@include media-medium {
  .mobile-carousel {
    display: block;
  }

  .desktop-carousel {
    display: none;
  }
}
