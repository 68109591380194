section {
  background: $main-background-color;
  overflow: hidden;
}

.show-overflow {
  overflow: visible;
}

.section-border {
  border-top: 1px solid $sub-border-color;
}

.content-header {
  text-align: center;
  padding: 0 0 2rem;
  max-width: 32rem;

  svg {
    position: absolute;
    width: 80px;
  }

  p {
    margin: 1em 0;
  }

  @include media-from-medium {
    padding: 3rem 0;
  }
}

.content-privacy {
  color: $main-font-color;
  margin: 0 auto;
  text-align: center;
  padding: 0rem 0;
  max-width: 32rem;

  h3 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 2.5em;
    margin-top: 1.5em;
  }
}

.content-terms {
  color: $main-font-color;
  margin: 0 auto;
  text-align: center;
  padding: 0rem 0;
  max-width: 32rem;

  h3 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 2.5em;
    margin-top: 1.5em;
  }
}

.terms-list {
  text-align: left;
}

.privacy-wrap {
  max-width: 57%;
  margin: 0 auto;
}

.content-item{
  flex-grow: 1;
  flex-basis: 0;
  width: 0;
  max-width: 50%;
  text-align: center;

  p {
    font-size: 1.15rem;
    margin: 1em 0;
  }

  span {
    font-weight: bold;
    display: inline-block;
    font-size: 0.75rem;
    margin: 1em 0 2.5em;
  }

  img {
    width: 70%;
  }
}

.text-wrap {
  max-width: 57%;
  margin: 0 auto;
}

.content-visual {
  padding: 0;
}

.section-information {
  background: $white;
  padding: 0 0 8em;

  .wrapper-compact {
    margin: 6em auto;
  }
}

.section-blank {
  background: $white;
}

@include media-medium {
  .content-item {
    order: 0;
    max-width: none;
    width: auto;
    flex-basis: auto;
  }

  .content-visual {
    order: -1;
    margin-bottom: 3em;
  }

  .text-wrap {
    max-width: none;
  }
}
