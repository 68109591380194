.visual-header {
  border-radius: $main-border-radius-shop;
  overflow: hidden;
  color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 -0.25em;

  .visual-information {
    padding: 1em;
  }
}

@include media-from-medium {
  .visual-header {
    margin: 0;

    .visual-information {
      padding: 4em;
    }
  }
}