#promo-banner {
  background-color: $turquoise;
  padding: 0.5em;
}

.banner-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: $white;
  text-align: center;
}