.domain-menu {
  background-color: $dark-blue-1;
  font-size: 20px;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  transform: translateX(-100%);
  transition: transform ease-in-out 0.25s;

  span {
    display: block;
    letter-spacing: 0;
    text-align: center;
    line-height: 60px;
    color: $white;
    margin: 1.75em;
  }

  svg {
    position: absolute;
    right: 2em;
    top: 2em;
  }

  ul {
    letter-spacing: 0;
    line-height: 50px;
  }

  li {
    text-align: center;
  }

  a, button {
    color: $white;
    text-decoration: none;
    background-color: transparent;
    border: none;
    font-weight: 300;
  }
}

.navigation-active {
  transform: translateX(0);
}

@include media-min-small {
  .domain-button-wrapper {
    display: none;
  }
}