.reviews {
  overflow: hidden;
  margin: 1em -0.5em;
}

.review-wrapper {
  margin: 1em 1em 2em;

  h3 {
    text-align: center;
    font-size: 20px;
    padding: 0 1em 1em;
  }
}

.review {
  background: $white;
  padding: 1.5em;
  box-sizing: border-box;
  margin: 0 0.25em;
  border-radius: 10px;
}

.review-title {
  font-weight: bold;
  margin-bottom: 1em;

  p i {
    margin-right: 0.5em;
    color: #0ab39e;
  }
}

.review-name {
  margin: auto;
}

.stars {
  i {
    display: none;
  }
}

@for $star from 1 through 5 {
  .stars[data-checked="#{$star}"] {
    i:nth-child(-n+#{$star}) {
      color: $main-brand-color;
      display: inline;
      padding-right: 0.25em;
    }
  }
}

.review-heading {
  overflow: hidden;
  position: relative;
  margin-top: 4em;
}

.review-footer {
  display: flex;
  margin: 1em 0.5em 0;
}

.review-picture {  
  display: block;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}