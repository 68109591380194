@include font-face(Sofia, '../../assets/font/SofiaProUltraLight',  (font-weight: normal, font-style: normal, font-display: swap), (file-types: woff));
@include font-face(Sofia, '../../assets/font/SofiaProUltraLight',  (font-weight: normal, font-style: italic, font-display: swap), (file-types: woff));
@include font-face(Sofia, '../../assets/font/SofiaProSemiBold',    (font-weight: bold, font-style: normal, font-display: swap), (file-types: woff));
@include font-face(Sofia, '../../assets/font/SofiaProLightItalic', (font-weight: normal, font-style: italic, font-display: swap), (file-types: woff));

@include font-face(Sofia, '../../assets/font/SofiaProUltraLight',  (font-weight: 100, font-style: normal, font-display: swap), (file-types: woff));
@include font-face(Sofia, '../../assets/font/SofiaProUltraLight',  (font-weight: 200, font-style: normal, font-display: swap), (file-types: woff));
@include font-face(Sofia, '../../assets/font/SofiaProLight',       (font-weight: 300, font-style: normal, font-display: swap), (file-types: woff));
@include font-face(Sofia, '../../assets/font/SofiaProLight',       (font-weight: 400, font-style: normal, font-display: swap), (file-types: woff));
@include font-face(Sofia, '../../assets/font/SofiaProSemiBold',    (font-weight: 500, font-style: normal, font-display: swap), (file-types: woff));
@include font-face(Sofia, '../../assets/font/SofiaProSemiBold',    (font-weight: 600, font-style: normal, font-display: swap), (file-types: woff));

$default-font:   Sofia, Arial, sans-serif;


h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.25rem;
}


.center-title {
  text-align: center;
  margin: 1em 0;
}
