.blend-information {
  background: $main-brand-color;
  text-align: center;
  font-weight: bold;
  color: $white;
  padding: 1.25em 0;
}

.blend-page {
  background: #fff;

  .bottom-cta-wrapper {
    background: none;
    position: static;
  }
}

@include media-medium {
  .ingredient {
    margin: 0.5em 0;
  }
}

@include media-small {
  .recommendation-title {
    font-size: 1.5rem;
  }

  .recommendation-intro,
  .capsule-intro {
    display: none;
  }

  .blend-page {
    .bottom-cta-wrapper {
      position: fixed;
      text-align: inherit;
      padding: 2em 1em;
      background: linear-gradient(rgba($main-background-color, 0), $main-background-color 50%);

      button {
        padding: 0.3rem 0.75rem 0.4rem;
      }
    }

    section:last-of-type {
      padding-bottom: 5em;
    }
  }
}

.blend-page #launcher {
  bottom: 1.3em !important;
}